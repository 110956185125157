<template>
  <div class="home-container page">
    <div class="home-top-bar">
      <span>ทั่วประเทศ</span>
      <span>Our time</span>
      <span>ทั่วประเทศ</span>
    </div>

    <div class="home-scroll">
      <div class="banner" >
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item>
            <van-image class="banner_img" round :src="require(`@/assets/banner/banner1.png`)">
            </van-image>
          </van-swipe-item>
          <van-swipe-item>
            <van-image class="banner_img" round :src="require(`@/assets/banner/banner2.png`)">
            </van-image>
          </van-swipe-item>
          <van-swipe-item>
            <van-image class="banner_img" round :src="require(`@/assets/banner/banner3.png`)">
            </van-image>
          </van-swipe-item>
          <van-swipe-item>
            <van-image class="banner_img" round :src="require(`@/assets/banner/banner4.png`)">
            </van-image>
          </van-swipe-item>
        </van-swipe>
      </div>


      <div class="describe">
        <ul>
          <li>
            <div style="height:100%">การจับคู่สด</div>
          </li>
          <li>
            <div class="scroll-cmp scroll-cmp_2" >
              <vue-seamless-scroll :data="items" :class-option = "classOption">
                <ul class="item">
                  <li v-for="(item, id) in items" :key="id">
                    <span>{{ item.name }}</span>
                    <span>({{ item.phone }})</span>
                    <div style="color: #fff;"><span>{{ item.detail }}</span></div>
                  </li>
                </ul>
              </vue-seamless-scroll>
              </div>
          </li>
        </ul>
        <div>แหล่งข้อมูลทั้งหมดบนแพลตฟอร์มนี้เป็นของแท้ ปลอดภัย และเชื่อถือได้ คุณสามารถเลือกสาวที่คุณชอบทางเเพลตฟอร์มหรือเดทในจังหวัดเดียวกันได้ทั่วประเทศและพบปะกับคุณเเบบสุดพิเศษ เข้าร่วมกับเราตอนนี้้</div>
      </div>


      <div class="home-body">
        <ul>
          <li :class="home_body==1 ? 'active' : ''" @click="home_body=1">สาวๆในเครือ</li>
          <li :class="home_body==2 ? 'active' : ''" @click="home_body=2">เเชร์ประสบการณ์ผู้ใช้งาน</li>
          <li :class="home_body==3 ? 'active' : ''" @click="home_body=3">เงื่อนไขการให้บริการ</li>
        </ul>

        <div v-if="home_body==1">
          <div class="xuanfei" v-for="(item,index) in girlData" :key="index">
            <div class="left-div">
              <div>ID:{{item.code}}</div>
              <div @click="toHomeDetail(item)">
                <ul>
                  <li>ชื่อเล่น: {{item.name}}</li>
                  <li>บุคลิก: {{item.character}}</li>
                  <li></li>
                  <li>พื้นที่: {{item.area}}</li>
                  <li>ความสูง: {{item.high}}</li>
                </ul>
              </div>
              <div>
                <ul>
                  <li><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAABIRJREFUaEPtWl1oHFUU/s7Miq8aERFFihbRh7agVuhDsS2CL75opWClah+Umuw9s6EiRgsdENQHze7cu6tEReNPIqJGwRfR+gMqilIfbEQRxArqm4l9a6AzR27ZDZNkN/Ozm92S2QvzsnO+853vnjvn/i2hYI0KphdDwZs948MMDzO8yXpgOKQ3WULXyBlmeJjhnD1QrVavdRxnGxFtA2CfawBcEnus5/9izx8ATorIz67r/lIul//MSb0urKdDWmt9MREdFJGDAG7vMuATRDQrIrPMvNSlr2V4TwTX6/XLRKQMwIq9vlfBWT9E9FsURW+7rmvK5fK/3fruSrDv+6WRkREr1D7XdRtMAv53APWFhYW67/vn8nLlFhwEwc1E9BKAm/KS58T9CGCCmT/Jg88l2BizT0Q+y0PYK4yIPOR53itZ/WUWbIxRIqKzEm2Q/ZPM/HQW35kEB0HgE9HxLAQbbSsi93ue92ZantSCjTGPi8gzaR2vY2enmHkAlzfn5q5dEtEBpdS7aRylEmyMsdPNTBqHHWxeBfCViJzyPO9ky6ZWq13hOM4OEdlORI81OyEXTVrRiYKr1eoW13W/ALAlVyTAfmaeS8I2Go2tYRi+DGBPkm2790T0t13sKKV+XQ+fKFhr/RqAB3ME8Z0NPusqqZuiSEQfKKXuzi1Ya22FWsFZ2zlmvmg1qDl37xGRqxzHORtF0fzi4uKc7/tn47Za60cAvJCV1NqLyBOe53WsNR0zrLW+2n53OYfyEWaeagVsfdmpjIjuaiPiDIBjzFyPvwuC4D0i2p9HtOu6t46Njf3Qduh3cqi1fg7A0RyEU8x8pIWbnJzcWiqVPk5aehLRs0qpiRau0WjcGIbhNwAuzRoDEb2ulGr7GbbNcK1W2+k4zrcA3KxkpVJpx+jo6E+x7H4J4LY0fqIoOlSpVN5q2RpjZpo7rzTwFTYiss/zPFtsV7S2goMgmLHbvMwswBlmtnve8y0IggNE9E4GP/PMbPfO55vW+hiApzLgl01F5H3P8+5JFKy1tvvYT/OQAPiamXfHAn4DwKEsvohou1LqlMXU6/U7oyj6KAt+le0dqzcZazIcBMHDRLRccDKSvcjMozHBtnDcksWHLWxKqQ8tZmpq6sqlpaV/suDjtkTESimz4rfVzgonuHBDulls8s6BF1LRmvU8777EotUUvJeIPs/z7Vwg01IYRdGuSqWyZvHRcaVljJkWkQdyiB74wgPA88z8aLvYOwpuNBo7wzD8PodgCxnk0vI0gN3M/Fcmwc2hPUFEmY5QmiQD2zwAOMzM050Slbg9NMbMiUi7RX9S8vu+PQQwzcyH1wssjeAbAJywW7okhR3e9+UAAMDpMAz3jo+P2yHdsSUKtshCHfG0uqpQh3gx0cdFxM85tDcEtmHHtDHRxTmIj4kuzlVLS3ShLtNaogt1XRqvQPZCPAxD5TjOvZv6Qnx12S3MXx7azTeF+FPLhky0PXaaamnZY86BuhsKHmj394F8mOE+dPJAKYYZHmj394F8mOE+dPJAKQqX4f8BYPRTW5RGL5kAAAAASUVORK5CYII="><span>{{item.browse}}k</span></li>
                  <li><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAABOBJREFUaEPtmmtoHFUUx///2SiKQdsKRqUoBfWD+kGREltRqlZBK6LQRLRaK4So+ZLt3DtJfEEQIjRzZ1O1vioSXwS0KC3EkrZY+sUg2hoQRNT6QFFo0agItWqyR2aSLNvsc3ZnNskm9+PuOed/fveeO3PPzBCLbHCR8WIJuN5XfGmFl1a4zmag4pIW110HYB3IJlCapubFOgCRw9T606jmSXp7T8c5Z90C4VoImyByPogTAL+AJd9BrC9p24fL1QsFLMZcAwvtENwF4LwiIkcg2Emtd5abyGw7GRw8A+PjfYBsAbCieBwOQeRNar2vlF7ZwJIyr0DQXirgrP+PAOleqq7hMH5B9VjsA7A2jB+A16n0Q8V8ygIWz7wLoCWkeJY5+6jUk+X4i+d2AwFsohz7PDY/UOlVhXxLAlcPOy1NvA9Y99O2/y6UjKTMOxC0Vgia7XaUSl+aL05R4MhgM8qyF0w8QNsez9mznhkE4O/XqMYuKp0zeQWBxXNfBvhwVOqZOMRBwNpM2/555jcx5gUQHZFrCZLU+tnsuHmBJZW6ApIeA3Ba5ElMBRwFrQdp20fFc12AOiad75GWa+k4x2fi5wf2XA+gHVMSM2HHABkG+FS8OuygUi8VBBbXXQWLnwFYFm8itYou71E5GwsDe+7T8c96rWADnd+pdObgklPS4plPAKyuaUpxi1mJ5dy69Q9fJh/wsRLHxrjTiz5+Q/oydnZ9kwMsO3aci39O/hq94lxH5HVUajQXeKD/KqQt/3ZUX0NwN7XenQvseXcCsqe+aH0aaadyXs0FNqYTxPa6AyaeoK2fyQVOuQ6E/fUHLPfQdvyO79SrtHj9WwDLP8TX10g0XMxk8sd8e/h2QD6oK1rBt9T6krwnLUmlVkDSv9UVMDhEpTblBQ6uZ54ZAnBv3UDPahFzT1rGbALxdt0Ac7KZdrd/XA5G/vbQmI9BNNcB9AEqfWs2R4F+uH8zYL2x8IHTHVRdmV644ApP7+WDAG5cwNDjSDRczmTSb4Yyo/AzrQGzEWnsWrjA8hqV0zY7/+JPLVNmGIINCxOaG6jU3nDArrsGFkcAnL2woLmNSvXky7n0g3hj2kAEncaCGMQhrLxoPVtbJysCDi5gxmwH0TnvgUVOgtbNM81+5cAtLQk0N4+AWD+/oUVROaliOZYs6Rlncd0r4b8fIvO+s5n7ieBzVKpkFZYNHJS2D21xP4AL5h7wlJvrIdq6rDNDKOAA+vltF+LfxFcAGucN9H8Ty9jT82c5+YQGDqBTqTMh6RPlCMRuQ6uZtp1pDkrpVQSc2dfG/QnkylIisf1Pa3WY7zv8PKoCnlptMwrBmtig8gc+jkTDDUwm/a0ValQNHEBX/UlEqJzHMDF5B7u7fwnlNW0cCXANoYcxMXkfu7v/qgQ2kpLOFhbPfRHgo5UmU9xPDJXjVBs7shXOXMgGTBsmpR/k8mqTm/Y/BoFDrd+KIl7kwEF5+x+wAf0gbqoqSXI/rLTDpPN5VXGynGMBDqB7ey00NvrQqrJkoynh2dqxAWdK3JjbQD4GyPXlgcsIYBkq9WF59uGsYgfOgHueD/14kSPp1wB90Fh775oBT926vKtBeSTrm03/XvoRKPuQ5h5qHfvL+JoChyu+eKyXgOOZ1/kTdWmF589axJPJolvh/wF8iXZMtgf/PgAAAABJRU5ErkJggg=="><span>{{item.like}}k</span></li>
                </ul>
              </div>
            </div>
            <div class="right-div"><img :src="item.imgUrl"></div>
            <i class="sign-i">ยืนยันแล้ว</i>
          </div>
        </div>


        <div v-if="home_body==2" class="scroll-cmp scroll-cmp_2" style="height: 5.33333rem;">
          <vue-seamless-scroll :data="xdItems" :class-option = "classOption">
            <ul class="item">
              <li v-for="(item, id) in xdItems" :key="id">
                <div style="color: #ffffff;">
                  <span>{{ item.name }}</span>
                  <span>({{ item.phone }})</span>
                </div>
                <div style="color: #fff;"><span>{{ item.detail }}</span></div>
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>

        <div  v-if="home_body==3" class="serverTerms">
          <ul>
            <li>
              <div>สาวๆบนเเพลตฟอร์ม</div>
              <div>เน็ตไอดอล  นางเเบบสาว  เเอร์โฮสเตส  นักเรียน นักศึกษา  ทุกอย่างมีให้บริการบนเเพลตฟอร์มนี้</div>
            </li>
            <li>
              <div>พื้นที่ให้บริการ</div>
              <div>ให้บริการในพื้นที่ใกล้เคียง ทุกที่ ทั่วประเทศ</div>
            </li>
          </ul>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  data() {
    return {
      loading: false,
      finished: false,
      home_body:1,
      notice: this.$t("index.loading"),
      basicData:[],
      isLoading: false,
      swiperOptions: {
        direction: 'horizontal', // 垂直滚动vertical
        slidesPerView: 'auto',
        loop: true, // 循环播放
        loopedSlides: 1,
        loopAdditionalSlides:1,
        speed:100, // 滑动速度
        autoplay:true,
      },
      itemSwiperOptions: {
        direction: 'vertical', // 垂直滚动vertical
        autoplay: {
          delay: 3000, // 自动播放的延迟时间为 2500 毫秒
          disableOnInteraction: true, // 用户交互后仍继续自动播放
          isableOnInteraction: true, // 确保交互后还能继续自动播放
        },
        loop: true, // 循环播放
        speed: 50, // 滑动速度
      },
      xdItemSwiperOptions: {
        direction: 'vertical', // 垂直滚动vertical
        autoplay: {
          delay: 3000, // 自动播放的延迟时间为 2500 毫秒
          disableOnInteraction: false, // 用户交互后仍继续自动播放
          isableOnInteraction: false, // 确保交互后还能继续自动播放
        },
        loop: true, // 循环播放
        speed: 50, // 滑动速度
      },
      items: [
        { id: 1, name: 'คุณอั๋น', phone: '086***5681', detail: 'นัดสำเร็จ...........' },
        { id: 2, name: 'คุณนัท', phone: '092***4845', detail: 'กำลังใช้บริการ...........' },
        { id: 3, name: 'คุณเติ้ล', phone: '081***5158', detail: 'นัดสำเร็จ..............' },
        { id: 4, name: 'คุณไปร์ท', phone: '092***7539', detail: 'กำลังใช้บริการ.......' },
        { id: 5, name: 'คุณคิง', phone: '089***8270', detail: 'นัดสำเร็จ...........' },
        { id: 6, name: 'คุณต่าย', phone: '095***0323', detail: 'กำลังใช้บริการ...........' },
        { id: 7, name: 'คุณไทย', phone: '093***6157', detail: 'นัดสำเร็จ..............' },
        { id: 8, name: 'คุณคิม', phone: '087***8810', detail: 'กำลังใช้บริการ........' },
        { id: 9, name: 'คุณเรด', phone: '098***5571', detail: 'นัดสำเร็จ...........' },
        { id: 10, name: 'คุณเก้ง', phone: '092***6031', detail: 'กำลังใช้บริการ...........' },
        { id: 11, name: 'คุณไทม์', phone: '080***9970', detail: 'นัดสำเร็จ..............' },
        { id: 12, name: 'คุณเเฟรงค์ ', phone: '087***2103', detail: 'กำลังใช้บริการ........' },
        { id: 13, name: 'คุณโบว์', phone: '098***9874', detail: 'นัดสำเร็จ...........' },
        { id: 14, name: 'คุณบอม', phone: '065***8069', detail: 'กำลังใช้บริการ...........' },
        { id: 15, name: 'คุณเเบงค์ ', phone: '081***8880', detail: 'นัดสำเร็จ..............' },
        { id: 16, name: 'คุณโจ', phone: '099***3201', detail: 'กำลังใช้บริการ...........' },
        { id: 17, name: 'คุณอาร์ท', phone: '092***0061', detail: 'นัดสำเร็จ...........' },
        { id: 18, name: 'คุณไอซ์', phone: '063***9931', detail: 'กำลังใช้บริการ...........' },
        { id: 19, name: 'คุณกล้า', phone: '082***1181', detail: 'นัดสำเร็จ..............' },
        { id: 20, name: 'คุณคิว', phone: '087***2087', detail: 'กำลังใช้บริการ........' },
        { id: 21, name: 'คุณใหม่', phone: '099***3003', detail: 'นัดสำเร็จ...........' },
        { id: 22, name: 'คุณเจ๋ง', phone: '089***0285', detail: 'กำลังใช้บริการ...........' },
        { id: 23, name: 'คุณหมี', phone: '091***4087', detail: 'นัดสำเร็จ..............' },
        { id: 24, name: 'คุณภูมิ', phone: '095***9742', detail: 'กำลังใช้บริการ........' },
        { id: 25, name: 'คุณกร', phone: '098***8756', detail: 'นัดสำเร็จ...........' },
        { id: 26, name: 'คุณนนท์', phone: '081***8809', detail: 'กำลังใช้บริการ...........' },
        { id: 27, name: 'คุณเกม', phone: '099***5874', detail: 'นัดสำเร็จ..............' },
        { id: 28, name: 'คุณโทน', phone: '082***9606', detail: 'กำลังใช้บริการ.......' },
        { id: 29, name: 'คุณเต๋า', phone: '086***8776', detail: 'นัดสำเร็จ...........' },
        { id: 30, name: 'คุณเเทน', phone: '095***4450', detail: 'กำลังใช้บริการ...........' },
        { id: 31, name: 'คุณปาย', phone: '089****3391', detail: 'นัดสำเร็จ..............' },
        { id: 32, name: 'คุณบาส', phone: '093***8368', detail: 'กำลังใช้บริการ.......' },
        { id: 33, name: 'คุณสิทธ์', phone: '065***5956', detail: 'นัดสำเร็จ...........' },
        { id: 34, name: 'คุณกบ', phone: '098****2406', detail: 'กำลังใช้บริการ...........' },
        { id: 35, name: 'คุณปิง', phone: '095***5420', detail: 'นัดสำเร็จ..............' },
        { id: 36, name: 'คุณกอร์ฟ', phone: '099***0979', detail: 'กำลังใช้บริการ......' },
        { id: 37, name: 'คุณเบียร์', phone: '093***4783', detail: 'นัดสำเร็จ...........' },
        { id: 38, name: 'คุณคอป', phone: '092***3088', detail: 'กำลังใช้บริการ...........' },
        { id: 39, name: 'คุณจ๊อบ', phone: '081***6697', detail: 'นัดสำเร็จ..............' },
        { id: 40, name: 'คุณอาร์ม', phone: '095***8111', detail: 'กำลังใช้บริการ........' },
        { id: 41, name: 'คุณซัน', phone: '086***5002', detail: 'นัดสำเร็จ...........' },
        { id: 42, name: 'คุณเป๋า', phone: '082***2078', detail: 'กำลังใช้บริการ...........' },
        { id: 43, name: 'คุณต๊ะ', phone: '095***0860', detail: 'นัดสำเร็จ..............' },
        { id: 44, name: 'คุณอู๋', phone: '063***8966', detail: 'กำลังใช้บริการ.......' },
        { id: 45, name: 'คุณหนึ่ง', phone: '096***5056', detail: 'นัดสำเร็จ...........' },
        { id: 46, name: 'คุณเอ', phone: '088***3485', detail: 'กำลังใช้บริการ...........' },
        { id: 47, name: 'คุณโรจน์', phone: '099****6660', detail: 'นัดสำเร็จ..............' },
        { id: 48, name: 'คุณถวิล', phone: '089***6596', detail: 'กำลังใช้บริการ......' },
        { id: 49, name: 'คุณสอง', phone: '081***0012', detail: 'นัดสำเร็จ...........' },
        { id: 50, name: 'คุณเท่', phone: '087***0212', detail: 'กำลังใช้บริการ...........' }
      ],
      xdItems: [
        { id: 1, name: 'คุณเก่ง', phone: '092***6500', detail: 'เพื่อนเเนะนำมาครับ สาวสวยมากครับ บริการถึงใจ' },
        { id: 2, name: 'คุณสอง', phone: '081***8801', detail: 'น้องนัดผมให้ไปหาที่บ้านไปเย็*น้องสักน้ำสองน้ำ  ผิวน้องคือดีมาก' },
        { id: 3, name: 'ุณต้า', phone: '086***2445', detail: 'เห็นเเค่รูปใจก็วูบวาปเเล้วครับ ไม่มีประสบการณ์ เเต่เร้าร่อนไม่ไหว อายุราวนี้เป็นสิ่งที่เด็ดจริงๆ' },
        { id: 4, name: 'คุณพจน์', phone: '096***5401', detail: 'ได้ทั้งเลีย ได้ทั้งแตกใน สุดยอดมากครับ' },
        { id: 5, name: 'คุณบอล', phone: '099***5656', detail: 'นมเเท้อย่างใหญ่ เเท้ทั้งตัว ข้างล่างอย่างเเน่น อย่างเสียวเลย' },
        { id: 6, name: 'คุณเบส', phone: '084***5020', detail: 'นัดโรงเเรมเเถวบ้านน้องมาหาเองเลย  เเตกปาก เเตกในได้หมดเลย' },
        { id: 7, name: 'คุณมอส', phone: '092***6997', detail: 'งานตกปกตัว จัดสดได้ด้วย ทำได้ทุกท่าเลย ถือว่าเด็ดมาก' },
        { id: 8, name: 'คุณบีส', phone: '062***8544', detail: 'ของเเทร่เลย  ขาวอวบ นมเป็นนม  เล่นเเตกไปหลายน้ำเลย' },
        { id: 9, name: 'คุณต้น', phone: '095***4863', detail: 'ไปรับน้องถึงห้องเลย  จัดห้องน้องเเล้วรับไปเที่ยวต่อ โดนน้องเย็..เดินเเทบไม่ไหวเลย' },
        { id: 10, name: 'คุณเสาร์ ', phone: '081***6720', detail: 'ดูดปากเเรกลิ้น  เย็.. นม  กระเเทกห...น้องอย่างฟิตทนไม่ใหวเเตกในไปหลายรอบเลย' },
        { id: 11, name: 'คุณนที ', phone: '092***8547', detail: 'น้องน่ารัก เอวดีมาก ขอบอกเลยติดใจมากๆครับ' },
        { id: 12, name: 'คุณแบงค์', phone: '098***2103', detail: 'น้องชอบให้ลงลิ้นก่อนเย็ดครับ อมเก่งเสียวมากครับ' },
        { id: 13, name: 'คุณนัท', phone: '098***1478', detail: 'น้องอมดีมากๆ เสียวจนแตกใส่ปาก น้องก็อมต่อ คืองานดีมากครับ ถูกใจ' },
        { id: 14, name: 'คุณทาม', phone: '098***2563', detail: 'น้องโยกจนน้องน้ำเยิ้ม น้องเสร็จแล้วให้ผมเลียต่อ ติดใจน้องมากต้องกลับมาหาอีกแน่นอนครับ！！' },
        { id: 15, name: 'คุณเติ้ล', phone: '098***7199', detail: 'เห้นรูปน้องแล้ว ตรงปกตามรีวิว ขาว เห็นแล้วขึ้นเลยครับผม' },
        { id: 16, name: 'คุณเก่ง', phone: '092***3621', detail: 'เด็ด น้องสู้ตายมากครับ เอากันสนุก ท่า 69 ผมต้องยกให้น้องเลย ดีย์' },
        { id: 17, name: 'คุณไท', phone: '098***5422', detail: 'น้องบริการดี พูดเพราะ ไม่หยิ่ง น่ารัก โดนไป2ที เพลียเลยทีเดียวครับ' },
        { id: 18, name: 'คุณต่าย', phone: '098***0056', detail: 'ถึงใจมากครับ น่ารัก เป็นกันเอง ลูบเล้าเก่ง เสียวไปหมด สุดยอดครับ' },
        { id: 19, name: 'คุณนัท', phone: '092***5214', detail: 'พี่ได้ปี้น้องแล้วบริการดีจับใจแต่พอเริ่มงานบนเตียงเท่านั้นแหละ เก่งจัด ตามใจ ให้ใส่ชุดนอนลื่นๆ จูบแลกกันเบาๆ' },
        { id: 20, name: 'คุณเต๋า', phone: '082***6008', detail: 'ูปร่าง/หน้าตา - ตรงตามปก9/10 หน้าตาเหมือนในรูปเดะ หุ่นกำลังดีตัวเล็ก ผิวขาวเหลือง นมเป็นนม ชอบมากๆ' },
        { id: 21, name: 'คุณเไอซ์', phone: '098***0001', detail: 'หุ่นสวยๆ นมขนาดใหญ่ บวกกับหน้าตาที่น่ารักของน้องอีกน้องควบม้าได้เก่งมาก ยั่วๆบดๆจนเราแตก ดีจริงๆ' },
        { id: 22, name: 'คุณคิมคิม', phone: '098***2322', detail: 'น้องยั่ว เราผลัดขึ้นไปเรื่อยๆ มันเสียวและโฟลว์ดีสุดๆ จบที่น้องขึ้นให้รอบสุดท้ายอย่างเฉียบ แตกเลย เด็ดขาดมาก' },
        { id: 23, name: 'คุณทีม', phone: '098***0141', detail: 'คนนี้สวยน่าตาดึงดูด นมสวย บริการเยี่ยมครับ เห็นหน้าแล้วทนไม่ไหวจริงๆจากเสร็จยากๆเจอจ้องตาไปต้องยอมแพ้พ่ายครับ ต้องมาลองชอบครับ' },
        { id: 24, name: 'คุณกอล์ฟ', phone: '098***1072', detail: 'น่ารักและเหมือนในภาพ เย้ายวนตั้งแต่วินาทีที่เดินเข้าห้องจนหมดเวลา บริการดี ดูแลดี ดูดเก่งมาก โดยส่วนตัวแล้วผมชอบผู้หญิงที่...ดูดเก่งอยู่แล้ว' },
        { id: 25, name: 'คุณอั้ม', phone: '097***8583', detail: 'ผิวขาว ทำตานิด ทำจมูกหน่อย ทำปากน้อยๆ ทำนมบึ้มๆ !! น้องอาบน้ำให้ เสียวมากอมสุดอมลึก น้องมีชุดตรีมเลือกได้หลายชุด งานดีย์' },
        { id: 26, name: 'คุณกล้า', phone: '096***1049', detail: 'น้องทำผมถูกใจมาก ผมจัดไป 3 ท่า เบสิก ท่าหมา จบด้วยน้องขึ้นให้ครับ งานตามใจนะครับชอบแบบไหนบอกน้องได้เลยครับ' },
        { id: 27, name: 'คุณเฟรน', phone: '098***5288', detail: 'น้องน่ารัก ครับ ตัวเล็กๆ ยิ้มเก่ง อมสด อาบน้ำให้ กูแลฟิวแฟนเลย ไม่ถือตัว ไม่ติดมือถือ แต่ติดผม เพราะผมเลียให้หมดครับ เด็ดมากๆ' },
        { id: 28, name: 'คุณโอ๊ค', phone: '066***9987', detail: 'น้องบอก จะเล็ก จะใหญ่ไม่ว่า แต่ลีลาต้องได้' },
        { id: 29, name: 'คุณเอก', phone: '094***7480', detail: 'นัดน้องมาสวิงกิ้งที่บ้านโครตมันส์เลยครับ' },
        { id: 30, name: 'คุณกันย์', phone: '063***0031', detail: 'น้องบอก 56 59 ก็เอาอยู่ จะเอาจนสำลักน้ำแตกแบบไม่ยั้ง' },
        { id: 31, name: 'คุณแก๊ป', phone: '084***1700', detail: 'นัดน้องๆไปเจอโรงแรม จะอมสด เลียไข่ ก็ได้หมด ขอแค่บอก น้องๆก็จัดให้' },
        { id: 32, name: 'คุณไพค์', phone: '091***6334', detail: 'เคยใช้บริการน้องผมสั้น ผมติดใจมาก จนต้องกลับมาใช้บริการอีก' },
        { id: 33, name: 'คุณแม็กซ์', phone: '099***2677', detail: 'เคยนัดน้องครั้งนึง หน้าตาดูซื่อๆ แต่เรื่องบนเตียงเด็ดสุดๆ' },
        { id: 34, name: 'คุณไนล์', phone: '087***8832', detail: 'ยังไม่ทันได้นัดเห็นแค่รูปก็แตกแล้ว ถ้าเจอตัวจริงจะขนาดไหนกัน' },
        { id: 35, name: 'คุณยอด', phone: '088***6816', detail: 'เจอน้องครั้งแรก สเปค ขอแบบจุกๆ ขอเท่าไหร่ก็ไม่เคยขัด' },
        { id: 36, name: 'คุณปาล์ม', phone: '074***4771', detail: 'นัดน้องมาเย็..บ้านเพื่อน น้องบอกขอแบบสุกี้รวมก็ได้ ผมก็เลยชวนเพื่อนมาแจมด้วย' },
        { id: 37, name: 'คุณปอนด์', phone: '084***8002', detail: 'เหงาๆไม่รู้จะทำอะไร จนได้มาใช้บริการกับเว็บนี้ บริการดีถึงใจสุดๆ สาวๆคือเด็ดๆทั้งนั้น' },
        { id: 38, name: 'คุณธีร์', phone: '095***3795', detail: 'น้องๆเว็บนี้น่ารักกันทุกคน ท่ายากก็เอาเรื่อง เอาสด แตกใน ได้หมด' },
        { id: 39, name: 'คุณแลนซ์', phone: '061***1672', detail: 'นัดน้องมามีอะไรกันที่บ้านแฟน ตอนแฟนไปทำงาน ทั้งน่าตื่นเต้น และก็เร้าใจมากครับ' },
        { id: 40, name: 'คุณแฟรงค์ ', phone: '092***5680', detail: 'ดูด เลีย อมสด แตกใน แถมล้างตู้เย็นให้อีกด้วย บอกเลยว่าฟินโครตๆ' },
        { id: 41, name: 'คุณทัพ', phone: '081***3091', detail: 'เพื่อนเคยมาใช้บริการเลยให้มาลองดู น้องเอวดีมาก ใส่ใจทุกรายละเอียดว่าเราจะชอบแบบไหน น้องก็จัดให้ทุกแบบเลยครับ' },
        { id: 42, name: 'คุณเบิร์ด', phone: '096***6929', detail: 'เว็บนี้สาวๆดีบริการถึงใจมาก มาใช้บริการกี่รอบก็ไม่ทำให้ผิดหวังเลยครับ' },
        { id: 43, name: 'คุณชล', phone: '065***1432', detail: 'ผมบอกชอบคนหื่นๆ เซ็กส์จัดๆ น้องก็จัดให้ผมทั้งคืนแบบไม่พักเลยครับ' },
        { id: 44, name: 'คุณแท่ง', phone: '097***4071', detail: 'ก่อนเจอน้องถ่ายภาพเซ็กซี่มาอวด ทำเอาผมอดใจไม่ไหวเลย' },
        { id: 45, name: 'คุณเฟิร์ส', phone: '085***4460', detail: 'เวลาได้ยัดน้องแล้วเสียงครางแบบเร้าใจผมมาก ชอบสุดๆเลยครับ' },
        { id: 46, name: 'คุณกอล์ฟ', phone: '085***1674', detail: 'หุ่นเอ็กซ์มาก นมเป็นนม เวลาน้องขึ้นให้แล้ว อธิบายไม่ถูกเลย' },
        { id: 47, name: 'คุณฟอร์ด', phone: '096***2932', detail: 'ตอนน้องโยก มันฟินสุดๆไปเลยครับ ยิ่งได้แตกในด้วย เอาใจผมไปเลยเต็มๆ' },
        { id: 48, name: 'คุณกัณต์', phone: '098***9682', detail: 'น้องคุยเก่งตลกเป็นกันเอง เฟรนลี่ดูแลดีที่สุดเท่าที่เคยเที่ยวมา ต้องมาลองครับ ประทับใจครับ' },
        { id: 49, name: 'คุณเบียร์', phone: '098***5417', detail: 'เซ็กซี่มาก อมเก่ง ช่วงล่างฟิตมาก เล่นได้ทุกท่าที่ขอ กลิ่นตัวหอมอ่อนๆ จุกสวยเล่นเพลินเลย ชอบ' },
        { id: 50, name: 'คุณโบว์', phone: '098***9682', detail: 'น้องเล้าโลมและอมเก่งจนผมต้องบอกพอแล้วเด๊ยวแตก ก็เลยให้น้องขึ้น น้องขึ้นเด็ดจริงๆ ขึ้นแบบเตียงแทบผมไม่ไหวเลยขอเปลี่ยนเป็นท่าขอบเตียง' }
      ],
      classOption() {
        //这里的计算属性需要绑定到上面的vue-seamless-scroll上
        return {
          step: -1, // 数值越大速度滚动越快
          limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
          hoverStop: false, // 是否开启鼠标悬停stop
          direction: 1, // 0向下 1向上 2向左 3向右
          openWatch: false, // 开启数据实时监控刷新dom
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        };
      },
      girlData:[
        {id:'1',code: "902***", name: 'แพม', character: 'เอวบางร่างน้อย SEXจัด น่ารัก', area: 'ระยอง', high: '162', browse: '124.6', like: '52.3', imgUrl: '/img/girl/1/1.png', detail1: '/img/girl/1/2.png', detail2: '/img/girl/1/3.png'},
        {id:'2',code: "601***", name: 'นาเดียร์', character: 'ผิมขาว นมแท้ น่ารัก ไม่หยิ่ง', area: 'อยุธยา', high: '162', browse: '276.4', like: '876.4', imgUrl: '/img/girl/2/4.png', detail1: '/img/girl/2/5.png', detail2: '/img/girl/2/6.png'},
        {id:'3',code: "228***", name: 'โดนัท', character: 'ตัวเล็ก น่ารัก ตรงปก', area: 'ขอนแก่น', high: '161', browse: '774.6', like: '97.4', imgUrl: '/img/girl/3/7.png', detail1: '/img/girl/3/8.png', detail2: '/img/girl/3/9.png'},
        {id:'4',code: "634***", name: 'ขิมขิม', character: 'อึ๋ม ผิวขาว ขี้เล่น', area: 'ปทุมธานี', high: '163', browse: '455.6', like: '354.3', imgUrl: '/img/girl/6/16.png', detail1: '/img/girl/6/17.png', detail2: '/img/girl/6/18.png'},
        {id:'5',code: "885***", name: 'มินนี่', character: 'หุ่นเอ็กซ์ ผมยาว ขี้อ้อน', area: 'ภูเก็ต', high: '159', browse: '996.1', like: '48.8', imgUrl: '/img/girl/4/10.png', detail1: '/img/girl/4/11.png', detail2: '/img/girl/4/12.png'},
        {id:'6',code: "652***", name: 'ปุยฝ้าย', character: 'ผิวขาว น่ารักตรงปก หุ่นดี', area: 'กรุงเทพ', high: '158', browse: '7462.2', like: '532', imgUrl: '/img/girl/5/13.png', detail1: '/img/girl/5/14.png', detail2: '/img/girl/5/15.png'},
        {id:'7',code: "976***", name: 'แพท', character: 'คุยเก่ง ชวนเล่น น่ารัก', area: 'ชลบุรี', high: '164', browse: '857.3', like: '23.8', imgUrl: '/img/girl/7/19.png', detail1: '/img/girl/7/20.png', detail2: '/img/girl/7/21.png'},
        {id:'8',code: "865***", name: 'อิงฟ้า', character: 'ขี้อ้อน เอาใจเก่ง แซ่บสุด', area: 'สมุทรปราการ', high: '163', browse: '746.1', like: '29.8', imgUrl: '/img/girl/8/22.png', detail1: '/img/girl/8/23.png', detail2: '/img/girl/8/24.png'},
        {id:'9',code: "276***", name: 'ฟองเบียร์', character: 'ตัวเล็กอุ้มง่าย ลีลาดี', area: 'นนทบุรี', high: '162', browse: '83.3', like: '24.7', imgUrl: '/img/girl/9/25.png', detail1: '/img/girl/9/26.png', detail2: '/img/girl/9/27.png'},
        {id:'10',code: "3845***", name: 'ไอซ์', character: 'ขี้อ้อน น่ารัก เอาใจเก่ง', area: 'กรุงเทพ', high: '164', browse: '962.1', like: '84.3', imgUrl: '/img/girl/10/28.png', detail1: '/img/girl/10/29.png', detail2: '/img/girl/10/30.png'},
        {id:'11',code: "459***", name: 'เมย์', character: 'นิสัยดี ขี้อ้อน เอาใจเก่ง ไม่งอแง', area: 'กำแพงเพชร', high: '162', browse: '877.3', like: '97.1', imgUrl: '/img/girl/11/31.png', detail1: '/img/girl/11/32.png', detail2: '/img/girl/11/33.png'},
        {id:'12',code: "745***", name: 'บูม', character: 'ยิ้มง่าย ขี้อ้อน เอาใจเก่ง ตามใจลูกค้า', area: 'พัทยา', high: '155', browse: '766.3', like: '87.1', imgUrl: '/img/girl/12/34.png', detail1: '/img/girl/12/35.png', detail2: '/img/girl/12/36.png'},
        {id:'13',code: "576***", name: 'แบมแบม', character: 'อัธยาศัยดี เอาใจเก่ง คุยเก่ง', area: 'บุรีรัมย์', high: '160', browse: '6435.2', like: '11.2', imgUrl: '/img/girl/13/37.png', detail1: '/img/girl/13/38.png', detail2: '/img/girl/13/39.png'},
        {id:'14',code: "534***", name: 'ไอซ์', character: 'น่ารัก คุยเก่ง ยิ้มง่าย', area: 'ลพบุรี', high: '157', browse: '265.3', like: '172.4', imgUrl: '/img/girl/14/40.png', detail1: '/img/girl/14/41.png', detail2: '/img/girl/14/42.png'},
        {id:'15',code: "353***", name: 'เพียว', character: 'น่ารัก นิสัยดี พูดจาเพราะ บริการดี', area: 'ปราจีนบุรี', high: '170', browse: '967.3', like: '12.3', imgUrl: '/img/girl/15/43.png', detail1: '/img/girl/15/44.png', detail2: '/img/girl/15/45.png'},
        {id:'16',code: "657***", name: 'ปาลิน', character: 'คุยเก่ง เป็นกันเอง เฟรนลี่ ขี้เล่น', area: 'ฉะเฉิงเทรา', high: '156', browse: '374.6', like: '43.2', imgUrl: '/img/girl/16/46.png', detail1: '/img/girl/16/47.png', detail2: '/img/girl/16/48.png'},
        {id:'17',code: "865***", name: 'ชินชิน', character: 'ตัวเล็ก เซ็กจัด ผิวขาวเนียน เอาใจเก่ง', area: 'ภูเก็ต', high: '170', browse: '726.3', like: '87.3', imgUrl: '/img/girl/17/49.png', detail1: '/img/girl/17/50.png', detail2: '/img/girl/17/51.png'},
        {id:'18',code: "583***", name: 'ป๊อปปี้', character: 'น่ารัก ขาว หมวย อึ๋ม', area: 'พัทยา', high: '166', browse: '767.3', like: '98.3', imgUrl: '/img/girl/18/52.png', detail1: '/img/girl/18/53.png', detail2: '/img/girl/18/54.png'},
        {id:'19',code: "907***", name: 'แพม', character: 'ขี้อ้อน เอาใจเก่ง ไม่งอแง บริการดี', area: 'เชียงใหม่', high: '165', browse: '345.1', like: '66.2', imgUrl: '/img/girl/19/56.png', detail1: '/img/girl/19/57.png', detail2: '/img/girl/19/58.png'},
        {id:'20',code: "237***", name: 'พิมพ์', character: 'ผิวขาวสวย น่ารัก อัธยาศัยดี', area: 'กรุงเทพ', high: '162', browse: '943.2', like: '21.5', imgUrl: '/img/girl/20/59.png', detail1: '/img/girl/20/60.png', detail2: '/img/girl/20/61.png'},
        {id:'21',code: "342***", name: 'ลูกชุบ', character: 'สวย เซ็กซี่ น่ารัก น่าเอ็นดู', area: 'อุดรธานี', high: '157', browse: '758.2', like: '88.3', imgUrl: '/img/girl/21/62.png', detail1: '/img/girl/21/63.png', detail2: '/img/girl/21/64.png'}
      ]
    };
  },
  components: {
    vueSeamlessScroll
  },
  mounted () {
  },
  methods:{
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast(this.$t("reservation.refresh"));
      }, 500);
    },
    getBasicConfig(){
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res=>{
        this.basicData = res.data;
		console.log(res.data);
        this.getNotice(this.basicData);//获取公告
        // this.getBanner(this.basicData);//获取banner
      })

    },
    getNotice(data){
      this.notice = data.notice;
    },
    // getBanner(data){
    //   // this.banners = data.banners;
    // },
    toHomeDetail(item)
    {
      this.$router.push({path:'/homeDetail',query:{...item}})
    }
  },
  created() {
    this.getBasicConfig();
  }
}

</script>

<style lang='less' scoped>

.home-container{
  height: calc(100% - 1.33333rem)
}

.home-container .home-scroll{
  width: calc(100% - .53333rem);
  height: calc(100% - 1.06667rem);
  margin: 0 auto;
  overflow-y: auto
}

.home-container .banner{
  height: 4.1984rem;
  position: relative
}

.home-container .banner .position-btn{
  position: absolute;
  top: 3.46667rem;
  right: .13333rem;
  z-index: 1000000;
  height: .53333rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .26667rem;
  font-size: .34667rem;
  padding: 0 .24rem;
  word-break: keep-all;
  background: linear-gradient(#fde3ca,#e7b486);
  color: #8a5623
}


.home-top-bar{
  width: 100%;
  height: 1.06667rem;
  padding: 0 .4rem 0 .26667rem;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.home-top-bar img{
  height: 1.06667rem;
  -o-object-fit: contain;
  object-fit: contain
}

.home-top-bar span{
  color: #fff
}

.home-top-bar span:first-child {
  font-size: .34667rem
}

.home-top-bar span:nth-child(2) {
  font-size: .48rem;
  font-weight: 700
}

.home-top-bar span:nth-child(3) {
  font-size: .34667rem;
  color: transparent
}

.home-top-bar i{
  color: #fff
}
.swiper-container,.swiper-slide,.banner_img{
  height: 100%;
  width: 100%;
}

.home-container .home-scroll{
  width: calc(100% - .53333rem);
  height: calc(100% - 1.06667rem);
  margin: 0 auto;
  overflow-y: auto
}
.home-container .banner{
  height: 4.1984rem;
  position: relative
}

.home-container .banner .position-btn{
  position: absolute;
  top: 3.46667rem;
  right: .13333rem;
  z-index: 1000000;
  height: .53333rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .26667rem;
  font-size: .34667rem;
  padding: 0 .24rem;
  word-break: keep-all;
  background: linear-gradient(#fde3ca,#e7b486);
  color: #8a5623
}
.banner .swiper-container .swiper-wrapper .swiper-slide .padding-b .img-wrapper{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.van-image--round {
  overflow: hidden;
  border-radius:0;
}


.describe {
  width: 100%;
  min-height: 5rem;
  border-radius: .26667rem;
  border: .02667rem solid #766350;
  margin: 0.3rem 0;
  margin-top: 2.26667rem;
  background: linear-gradient(120deg,#eb748e,#eb748e);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: .21333rem;
  position: relative;
  overflow: hidden
}

.describe>ul {
  height: 2.13333rem;
  display: flex;
  align-items: flex-start
}

.describe>ul li {
  height: 100%
}

.describe>ul>li:first-child {
  width: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-right: .08rem solid #e6b98a
}

.describe>ul>li:first-child div {
  color: #fff;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: -0.68rem
}

.describe>ul>li:first-child>div:first-child {
  font-size: .42667rem;
  font-weight: 700;
  height: 1.06667rem;
  text-align: center
}

.describe>ul>li:nth-child(2) {
  padding: 0 0 0 .13333rem;
  width: calc(100% - 3.2rem);
  font-size: .32rem;
  line-height: .66667rem;
  color: #fff
}

.describe>div {
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  line-height: .64rem;
  font-size: -.68rem
}

.describe>div:nth-child(3) {
  font-size: .4rem;
  height: .8rem!important;
  font-weight: 700
}

.describe>div:nth-child(4) {
  width: 3.2rem;
  height: .8rem;
  background-color: #e6b98a;
  color: #fff;
  font-size: .48rem;
  border-radius: .4rem;
  margin: .13333rem auto;
  font-weight: 700
}

.describe:after,.describe:before {
  content: "";
  position: absolute;
  width: .53333rem;
  height: 6.66667rem;
  //background: linear-gradient(90deg,rgba(74,70,70,.29),transparent);
  transform: rotate(50deg)
}

.describe:after {
  left: 50%
}



.home-body{
  width: 100%
}

.home-body>ul{
  height: .8rem;
  display: flex;
  overflow: auto
}

.home-body>ul>li{
  height: .53333rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .26667rem;
  background: linear-gradient(90deg,#eb748e,#eb748e);
  font-size: .34667rem;
  padding: 0 .1rem;
  color: #fff;
  word-break: keep-all
}

.home-body>ul>li.active{
  background: linear-gradient(#fde3ca,#e7b486);
  color: #8a5623
}

.home-body>ul>li:not(:last-child) {
  margin-right: .13333rem
}




.xuanfei{
  width: 100%;
  height: 4rem;
  border-radius: .26667rem;
  border: .02667rem solid #766350;
  margin-bottom: .26667rem;
  background: linear-gradient(120deg,#eb748e,#eb748e);
  display: flex;
  justify-content: space-between;
  padding: .21333rem;
  position: relative;
  overflow: hidden
}

.xuanfei .left-div{
  width: 5.33333rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center
}

.xuanfei .left-div>div:first-child {
  background: linear-gradient(90deg,#efcba7,#dcab76);
  color: #8d5825;
  height: .53333rem;
  padding: 0 .4rem;
  border-radius: .26667rem;
  line-height: .53333rem;
  text-align: center;
  font-size: .42667rem
}

.xuanfei .left-div>div:nth-child(2) {
  margin: .21333rem 0;
  padding-left: .26667rem
}

.xuanfei .left-div>div:nth-child(2) ul li{
  height: .48rem;
  line-height: .48rem;
  text-align: left;
  font-size: .34667rem;
  color: #fff
}

.xuanfei .left-div>div:nth-child(3) ul{
  display: flex
}

.xuanfei .left-div>div:nth-child(3) ul li{
  display: flex;
  align-items: center;
  justify-content: flex-start
}

.xuanfei .left-div>div:nth-child(3) ul li img{
  height: .34667rem;
  margin-right: .05333rem
}

.xuanfei .left-div>div:nth-child(3) ul li span{
  font-size: .32rem;
  color: #696969
}

.xuanfei .left-div>div:nth-child(3) ul>li:first-child {
  margin-right: .13333rem
}

.xuanfei .right-div{
  width: 3.2rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center
}

.xuanfei .right-div img{
  width: 2.66667rem;
  height: 2.66667rem;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%
}

.xuanfei .sign-i{
  position: absolute;
  right: -.64rem;
  top: .16rem;
  background: #ff6056;
  font-size: .32rem;
  padding: .10667rem .64rem;
  font-style: normal;
  transform: rotate(45deg);
  color: #fff
}

.scroll-cmp {
  width: 100%;
  height: 100%;
  border-radius: 0.26667rem;
  border: 0.02667rem solid #766350;
  background: linear-gradient(120deg, #eb748e, #eb748e);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.21333rem;
  position: relative;
  overflow: hidden;
}
.scroll-cmp .swper_item_detail{
  height: 25%;
}
.serverTerms {
  width: 100%;
  min-height: 4rem;
  border-radius: 0.26667rem;
  border: 0.02667rem solid #766350;
  margin: 0.26667rem 0;
  background: linear-gradient(120deg, #eb748e, #eb748e);
  display: flex;
  align-items: center;
  padding: 0.21333rem;
  position: relative;
  overflow: hidden;
}
.serverTerms ul li>div:first-child {
  font-size: .42667rem;
  color: #ffffff;
  font-weight: 700
}

.serverTerms ul li>div:nth-child(2) {
  font-size: .34667rem;
  color: #fff
}

.serverTerms ul>li:not(:last-child) {
  margin-bottom: .08rem
}

.serverTerms:after,.serverTerms:before {
  content: "";
  position: absolute;
  width: .53333rem;
  height: 6.66667rem;
  //background: linear-gradient(90deg,rgba(74,70,70,.29),transparent);
  transform: rotate(50deg)
}

.serverTerms:after {
  left: 50%
}
</style>
