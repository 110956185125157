<template>
      <div class="login">
        <van-nav-bar class="nav">
          <template #left>
            <van-icon name="arrow-left" color="#fff" @click="back()" />
          </template>
          <template #right>
            <div class="language" @click="$router.push('/language')">
              <img :src="require('../images/language/' + lang + '.png')" />
            </div>
          </template>
        </van-nav-bar>
        <div class="wrapper">
         <div class="title">
           <div class="logoCon">
<!--             <div class="user-wrapper">-->
<!--               <div class="van-image van-image&#45;&#45;round avatar-img" style="width: 50px; height: 50px;">-->
<!--                 <img src="/img/login/logo.png" class="van-image__img" style="object-fit: contain;">-->
<!--               </div>-->
<!--             </div>-->
             <img src="/img/login/logo.png" class="logoImg">
             Our time</div>
       </div>
          <div class="loginForm">
            <van-field
                v-model="username"
                clearable
                input-align="center"
                class="input"
                :placeholder="$t('auth.username_place')"
            />
            <van-field
                v-model="password"
                :type="passwordType"
                input-align="center"
                class="input"
                :placeholder="$t('auth.pwd_place')"
            />
            <div class="reset-text">
              <span>{{$t("auth.forgetpwd")}}?</span>
            </div>
            <van-button
                class="login-btn"
                type="primary"
                size="normal"
                @click="doLogin()"
            >{{$t("auth.login")}}
            </van-button>
            <van-button @click="toRegister()" class="regist-btn">
              {{$t("auth.no_account")}}
            </van-button>
          </div>
        </div>
      </div>
</template>

<script>
export default {
  model: {
    prop: "inputValue",
    event: "input",
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      username: "",
      lang: 'en_us',
      password: this.inputValue,
      passwordType: "password",
    };
  },
  mounted(){
    // localStorage.setItem("lang", 'ms_my');
    this.lang = localStorage.getItem("lang") || 'th_th';
  },
  methods: {
    switchPasswordType() {
      this.passwordType =
          this.passwordType === "password" ? "text" : "password";
    },
    back() {
      return window.history.back();
    },
    toRegister() {
      this.$router.push("Register");
    },
    doLogin() {
      if (
          this.username === "" ||
          this.username === null ||
          this.username === undefined
      ) {
        this.$toast(this.$t("auth.username_place"));
        return false;
      }
      if (
          this.password === "" ||
          this.password === null ||
          this.password === undefined
      ) {
        this.$toast(this.$t("auth.pwd_place"));
        return false;
      }
      this.$http({
        url: "member_login",
        method: "post",
        data: {
          username: this.username,
          password: this.password,
          lang: this.lang
        },
      }).then((res) => {
        if (res.code === 200) {
          this.$toast.success(res.msg);
          localStorage.setItem("token", res.data.id);
          this.$router.push("home");
        } else {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (localStorage.getItem("token")) {
      return window.history.back();
    }
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.login {
  height: 100%;
  background-color: #eb748e;
}
.login .nav{
  background: none;
}

.language{
  position: absolute;
  top: 4px;
  right: 0;
  height: 80px;
  img{
    height: 100%;
  }
}

//
//.login .wrapper {
//  overflow-y: auto;
//  -webkit-overflow-scrolling: touch;
//}
//.logo-container {
//  margin: 0 auto;
//  width: 45%;
//}
//.logo-container .logo-wrapper {
//  position: relative;
//  padding-bottom: 62.5%;
//}
//.logo-container .logo-wrapper .logo-img {
//  position: absolute;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//  -o-object-fit: contain;
//  object-fit: contain;
//}
//.login .wrapper .title {
//  line-height: 100px;
//  text-align: center;
//  font-size: 45px;
//  font-weight: 700;
//  color: #fff;
//  letter-spacing: 5px;
//}
//.login .wrapper .loginForm {
//  padding: 60px;
//}
//.login .wrapper .loginForm .input {
//  padding: 10px 20px;
//  margin-top: 40px;
//  border-radius: 50px;
//  text-align: center;
//  line-height: 80px;
//  font-size: 30px;
//  color: #4e4e4e;
//}
//
//::v-deep .van-field__right-icon .van-icon {
//  font-size: 50px;
//}
//::v-deep .van-icon {
//  font-size: 50px;
//}
//.login .wrapper .loginForm .reset-text {
//  margin: 30px 15px;
//  display: flex;
//  align-items: center;
//  justify-content: flex-end;
//}
//.login .wrapper .loginForm .reset-text span {
//  color: #fff;
//  font-size: 25px;
//  font-weight: 500;
//  line-height: 15px;
//}
//.login .wrapper .loginForm .register-text {
//  margin: 10px 0;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//}
//.login .wrapper .loginForm .register-text span {
//  color: #fff;
//  font-size: 25px;
//  font-weight: 500;
//  line-height: 20px;
//}
//.login .wrapper .loginForm .active {
//}
//.login .wrapper .loginForm .login-btn {
//  margin-top: 85px;
//  width: 100%;
//  height: 100px;
//  border-radius: 50px;
//  color: #fff;
//  background-color: #ff976a;
//  font-size: 30px;
//  font-weight: bolder;
//  border: none;
//}

/deep/::placeholder{
  color: #fff;
}

.user-wrapper .avatar-img {
  border: 0.05333rem solid rgb(255, 255, 255);
  margin-top: 7.267vw;
  margin-right: 2.933vw
}


.login .wrapper{
  height: calc(100% - 1.22667rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.login .wrapper .title{
  line-height: 1.86667rem;
  text-align: center;
  font-size: .48rem;
  font-weight: 700;
  color: #fff;
  letter-spacing: .05333rem;
}

.login .wrapper .login-input-border .input{
  border: .02667rem solid #fff
}

.login .wrapper .loginForm{
  padding: 0 .8rem
}

.login .wrapper .loginForm .input{
  padding: .26667rem .4rem;
  margin-top: .53333rem;
  text-align: center;
  line-height: .69333rem;
  font-size: .4rem;
  color: #4e4e4e
}

.login .wrapper .loginForm .input .left-icon{
  color: #fff
}

.login .wrapper .loginForm .input .right-icon{
  color: #979799
}

.login .wrapper .loginForm .reset-text{
  margin: .4rem .26667rem;
  display: flex;
  align-items: center;
  justify-content: flex-end
}

.login .wrapper .loginForm .reset-text span{
  color: #fff;
  font-size: .32rem;
  font-weight: 500;
  line-height: .42667rem
}

.login .wrapper .loginForm .password-text{
  margin: .53333rem 0;
  display: flex;
  align-items: center
}

.login .wrapper .loginForm .password-text span{
  color: #fff;
  font-size: .37333rem;
  font-weight: 500;
  line-height: .42667rem
}

.login .wrapper .loginForm .password-text>span[data-v-53069c29]:first-child {
  margin-right: .4rem
}

.login .wrapper .loginForm .login-btn{
  margin-top: .8rem;
  width: 100%;
  height: 1.22667rem;
  line-height: 1.22667rem;
  background: linear-gradient(#fde3ca,#e7b486);
  color: #fff;
  font-size: .42667rem;
  font-weight: bolder;
  border: none
}

.login .wrapper .loginForm .login-btn.active{
  color: #282828;
  background-color: #e6b98a
}

.login .wrapper .loginForm .regist-btn{
  margin-top: 1.33333rem;
  width: 100%;
  height: 1.22667rem;
  line-height: 1.22667rem;
  background: linear-gradient(#716c68,#cdcac7);
  color: #fff;
  font-size: .37333rem;
  font-weight: bolder;
  border: none
}

.login .wrapper .loginForm .regist-btn.active{
  color: #282828;
  background-color: #e6b98a
}
.logoCon{
  display: flex;
  align-items: center;
  justify-content: center;
  .logoImg{
    height: 12vw;
    width: 12vw;
    margin-right: 3vw;
  }
}

</style>
